import React from "react";

import { SVGProps } from "react";

/**
 * @type YouTubeIconProps
 */
export type YouTubeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const YouTubeIcon
 */
const YouTubeIcon = (props: YouTubeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.956 33.956">
            <path id="Path_666" data-name="Path 666" d="M62.173,126a1.974,1.974,0,0,1-1.3-1.267c-.565-1.553-.732-8.048.355-9.327a2.1,2.1,0,0,1,1.419-.755c2.93-.315,11.984-.27,13.028.106a2.038,2.038,0,0,1,1.281,1.232c.619,1.6.64,7.445-.081,8.992a1.986,1.986,0,0,1-.919.927C74.86,126.481,63.612,126.474,62.173,126Zm4.654-3.045L72.1,120.22l-5.276-2.753Z" transform="translate(-51.662 -103.422)" fill="#fff" fillRule="evenodd"/>
        </svg>
    );
};

export default YouTubeIcon;
