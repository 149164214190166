import {applyMiddleware, combineReducers, Reducer} from "redux";
import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "@js/reducer/filterReducer";
import informationReducer from "@js/reducer/informationReducer";
import securityReducer from "@js/reducer/securityReducer";
import statusReducer from "@js/reducer/statusReducer";
import thunk from "redux-thunk";
import StoreState from "@js/store/StoreState";

const reducer: Reducer = combineReducers({
    filter: filterReducer,
    information: informationReducer,
    security: securityReducer,
    status: statusReducer
});

const storeState = typeof window == "object" && localStorage.getItem("storeState");
const storeStateParsed = storeState && JSON.parse(storeState);

const preloadedState: StoreState = {
    filter: {
        deliveryTime: storeStateParsed?.filter?.deliveryTime || "",
        methodology: storeStateParsed?.filter?.methodology || "basic",
        orderBy: storeStateParsed?.filter?.orderBy || "price",
        priceMax: storeStateParsed?.filter?.priceMax || 0,
        priceMin: storeStateParsed?.filter?.priceMin || 0,
        priceTierMax: storeStateParsed?.filter?.priceTierMax || 0,
        priceTierMin: storeStateParsed?.filter?.priceTierMin || 0,
        reviewAverage: storeStateParsed?.filter?.reviewAverage || ""
    },
    information: {
        area: storeStateParsed?.information?.area || "",
        areaCode: storeStateParsed?.information?.areaCode || "",
        areaManual: storeStateParsed?.information?.areaManual || false,
        buildingType: storeStateParsed?.information?.buildingType || "",
        buildingTypeGroup: storeStateParsed?.information?.buildingTypeGroup || "",
        zipCode: storeStateParsed?.information?.zipCode || "",
        houseNumber: storeStateParsed?.information?.houseNumber || "",
        houseNumberAddition: storeStateParsed?.information?.houseNumberAddition || "",
        promotion: storeStateParsed?.information?.promotion || null
    },
    security: {
        token: storeStateParsed?.security?.token || "",
        expireDate: storeStateParsed?.security?.expireDate || "",
        firstName: storeStateParsed?.security?.firstName || "",
        lastName: storeStateParsed?.security?.lastName || "",
        username: storeStateParsed?.security?.username || ""
    },
    status: {
        open: 0,
        profileComplete: false
    }
};

const enhancers = applyMiddleware(thunk);

export const store = configureStore({
    reducer: reducer,
    middleware: [thunk],
    preloadedState,
    enhancers: [enhancers]
});


if (typeof window == "object") {
    store.subscribe(() => {
        const storeState: StoreState = store.getState();

        localStorage.setItem("storeState", JSON.stringify({
            filter: {
                deliveryTime: storeState.filter.deliveryTime,
                methodology: storeState.filter.methodology,
                orderBy: storeState.filter.orderBy,
                priceMax: storeState.filter.priceMax,
                priceMin: storeState.filter.priceMin,
                priceTierMax: storeState.filter.priceTierMax,
                priceTierMin: storeState.filter.priceTierMin,
                reviewAverage: storeState.filter.reviewAverage
            },
            information: {
                area: storeState.information.area,
                areaCode: storeState.information.areaCode,
                areaManual: storeState.information.areaManual,
                buildingType: storeState.information.buildingType,
                buildingTypeGroup: storeState.information.buildingTypeGroup,
                zipCode: storeState.information.zipCode,
                houseNumber: storeState.information.houseNumber,
                houseNumberAddition: storeState.information.houseNumberAddition,
                promotion: storeState.information.promotion
            },
            security: {
                token: storeState.security.token,
                expireDate: storeState.security.expireDate,
                firstName: storeState.security.firstName,
                lastName: storeState.security.lastName,
                username: storeState.security.username
            }
        }));
    });
}

export default store;