/**
 * @const SET_ADVISOR_URL
 * @const SET_AREA
 * @const SET_AREA_CODE
 * @const SET_AREA_CODE_BAG
 * @const SET_AREA_MANUAL
 * @const SET_BUILDING_TYPE_GROUP
 * @const SET_BUILDING_TYPE
 * @const SET_HAS_COMPLETED
 * @const SET_HAS_VISITED
 * @const SET_HOUSE_NUMBER
 * @const SET_HOUSE_NUMBER_ADDITION
 * @const SET_PROMOTION
 * @const SET_ZIP_CODE
 */
export const SET_ADVISOR_URL = "Information.SetAdvisorUrl";
export const SET_AREA = "Information.SetArea";
export const SET_AREA_CODE = "Information.SetAreaCode";
export const SET_AREA_CODE_BAG = "Information.SetAreaCodeBag";
export const SET_AREA_MANUAL = "Information.SetAreaManual";
export const SET_BUILDING_TYPE_GROUP = "Information.SetBuildingTypeGroup";
export const SET_BUILDING_TYPE = "Information.SetBuildingType";
export const SET_HAS_COMPLETED = "Information.SetHasCompleted";
export const SET_HAS_VISITED = "Information.SetHasVisited";
export const SET_HOUSE_NUMBER = "Information.SetHouseNumber";
export const SET_HOUSE_NUMBER_ADDITION = "Information.SetHouseNumberAddition";
export const SET_PROMOTION = "Information.SetPromotion";
export const SET_ZIP_CODE = "Information.SetZipCode";
