import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import SiteReviews from "@js/component/SiteReviews";
import EnvelopeIcon from "@js/component/icon/EnvelopeIcon";
import AnchorButton from "@js/component/AnchorButton";
import PhoneWhatsAppIcon from "@js/component/icon/PhoneWhatsAppIcon";

import * as classNames from "@css/component/TopBar.module.scss";

/**
 * @type TopBarProps
 */
export type TopBarProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const TopBar
 */
const TopBar = (props: TopBarProps) => {
    const {
        className,
        ...restProps
    } = props;

    const onMailTo = () => {
        typeof window == "object" && window.gtag && window.gtag("event", "mailto_click");
    };

    return (
        <div { ...restProps } className={ clsx(classNames.topBar, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.contact }>
                    <span className={ classNames.labelText }>Vragen?</span>
                    <AnchorButton
                        className={ classNames.linkButton }
                        href="mailto:info@woninglabel.nl"
                        onClick={ onMailTo }
                    >
                        <EnvelopeIcon className={ classNames.icon } />
                        <span className={ classNames.text }>info@woninglabel.nl</span>
                    </AnchorButton>
                    <AnchorButton className={ classNames.linkButton } href="tel:0850162080">
                        <PhoneWhatsAppIcon className={ classNames.icon } />
                        <span className={ classNames.text }>Bel of app: 085 – 01 620 80</span>
                    </AnchorButton>
                </div>
                <SiteReviews />
            </div>
        </div>
    );
};

export default TopBar;
