import React, { DetailedHTMLProps, forwardRef, HTMLAttributes, Ref } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import clsx from "clsx";

import * as classNames from "@css/component/SiteReviews.module.scss";
import ThumbsUpIcon from "@js/component/icon/ThumbsUpIcon";
import Stars from "@js/component/Stars";

/**
 * @type SiteReviewsProps
 */
export type SiteReviewsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    variant?: "starsOnly";
};

/**
 * @type SiteReviewData
 */
type SiteReviewData = {
    siteReview: {
        totalCount: number;
        totalScore: number;
        totalScorePercentage: number;
    }
};

const SiteReviews = (props: SiteReviewsProps, ref: Ref<HTMLDivElement>) => {
    const {
        variant,
        className,
        ...restProps
    } = props;

    const data: SiteReviewData = useStaticQuery(graphql`
        query {
            siteReview {
                totalCount
                totalScore
                totalScorePercentage
             }
         }
    `);

    return (
        <div { ...restProps } className={ classNames.siteReviews } ref={ ref }>
            <a className={ clsx(classNames.siteReview, { [classNames.starsOnly]: "starsOnly" === variant }) }
                target="_blank"
                rel="noopener noreferrer"
                href="//www.google.com/maps/place/Woninglabel.nl/@52.1273704,5.0458288,17z/data=!3m1!4b1!4m5!3m4!1s0x47c9cde1dd6f973b:0x75cb01829791b934!8m2!3d52.1273704!4d5.0458288"
            >
                { "starsOnly" !== variant &&
                    <>
                        <ThumbsUpIcon className={ classNames.thumbsUpIcon } />
                        <label className={classNames.label}>Klantbeoordeling:</label>
                    </>
                }

                { "starsOnly" !== variant &&
                    <span className={classNames.totalScore}>
                        {data.siteReview.totalScore} <span className={classNames.divider}>/</span> 5
                    </span>
                }

                <Stars
                    backgroundColor="#41517C"
                    className={ classNames.stars }
                    foregroundColor="#FFC402"
                    foregroundPercentage={ data.siteReview.totalScorePercentage }
                />
            </a>
        </div>
    );
};

export default forwardRef(SiteReviews);