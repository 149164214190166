import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { DetailedHTMLProps } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { HTMLAttributes } from "react";
import { Link } from "gatsby";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import AnchorButton from "@js/component/AnchorButton";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import EnvelopeIcon from "@js/component/icon/EnvelopeIcon";
import FacebookIcon from "@js/component/icon/FacebookIcon";
import Heading from "@js/component/Heading";
import InstagramIcon from "@js/component/icon/InstagramIcon";
import LinkButton from "@js/component/LinkButton";
import LinkedInIcon from "@js/component/icon/LinkedInIcon";
import Logo from "@js/component/Logo";
import TagIcon from "@js/component/icon/TagIcon";
import UserFriendsIcon from "@js/component/icon/UserFriendsIcon";
import PhoneWhatsAppIcon from "@js/component/icon/PhoneWhatsAppIcon";
import {isLoggedIn} from "@js/services/auth";
import ThListIcon from "@js/component/icon/ThListIcon";
import YouTubeIcon from "@js/component/icon/YouTubeIcon";
import CompareIcon from "@js/component/icon/CompareIcon";

import * as classNames from "@css/component/SiteFooter.module.scss";
import UserSingleIcon from "@js/component/icon/UserSingleIcon";
import RegisterIcon from "@js/component/icon/RegisterIcon";

/**
 * @type WordpressMenu
 */
type WordpressMenu = {
    menuItems: {
        nodes: {
            id: string;
            label: string;
            url: string;
        }[];
    };
};

/**
 * @type WordpressFooterMenuOneData
 */
type WordpressFooterMenuOneData = {
    wordpressFooterMenuOne: WordpressMenu;
};

/**
 * @type WordpressFooterMenuTwoData
 */
type WordpressFooterMenuTwoData = {
    wordpressFooterMenuTwo: WordpressMenu;
};

/**
 * @type WordpressFooterMenuThreeData
 */
type WordpressFooterMenuThreeData = {
    wordpressFooterMenuThree: WordpressMenu;
};

/**
 * @type Data
 */
type Data =
    WordpressFooterMenuOneData &
    WordpressFooterMenuTwoData &
    WordpressFooterMenuThreeData;

/**
 * @type SiteFooterProps
 */
export type SiteFooterProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const SiteFooter
 */
const SiteFooter = (props: SiteFooterProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: Data = useStaticQuery(graphql`
        query {
            wordpressFooterMenuOne: wordpressMenu(slug: { eq: "footer-menu-one" }) {
                menuItems {
                    nodes {
                        id
                        label
                        url
                    }
                }
            }
            wordpressFooterMenuTwo: wordpressMenu(slug: { eq: "footer-menu-two" }) {
                menuItems {
                    nodes {
                        id
                        label
                        url
                    }
                }
            }
            wordpressFooterMenuThree: wordpressMenu(slug: { eq: "footer-menu-three" }) {
                menuItems {
                    nodes {
                        id
                        label
                        url
                    }
                }
            }
        }
    `);

    const onMailTo = () => {
        typeof window == "object" && window.gtag && window.gtag("event", "mailto_click");
    };

    return (
        <footer { ...restProps } className={ clsx(classNames.siteFooter, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.blocks }>
                    <section className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="small">Woninglabel.nl</Heading>
                        <nav className={ classNames.navigation }>
                            <ul className={ classNames.list }>
                                { data.wordpressFooterMenuOne.menuItems.nodes.map((menuItemNode) => (
                                    <li className={ classNames.listItem } key={ menuItemNode.id }>
                                        <a className={ classNames.anchor } href={ menuItemNode.url }>
                                            <ChevronRightIcon className={ classNames.icon } /> { menuItemNode.label }
                                        </a>
                                    </li>
                                )) }
                            </ul>
                        </nav>
                        <div className={classNames.linkButtons}>
                            <LinkButton className={classNames.linkButton} to="/vergelijk">
                                <CompareIcon className={classNames.icon}/>
                                <span className={classNames.text}>Vergelijk adviseurs</span>
                            </LinkButton>
                        </div>
                    </section>
                    <section className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="small">Voor adviseurs</Heading>
                        <nav className={ classNames.navigation }>
                            <ul className={ classNames.list }>
                                { data.wordpressFooterMenuTwo.menuItems.nodes.map((menuItemNode) => (
                                    <li className={ classNames.listItem } key={ menuItemNode.id }>
                                        <a className={ classNames.anchor } href={ menuItemNode.url }>
                                            <ChevronRightIcon className={ classNames.icon } /> { menuItemNode.label }
                                        </a>
                                    </li>
                                )) }
                            </ul>
                        </nav>
                        { !isLoggedIn() &&
                            <div className={classNames.linkButtons}>
                                <LinkButton className={ clsx(classNames.linkButton, classNames.login) } to="/portaal/login">
                                    <UserSingleIcon className={classNames.icon}/>
                                    <span className={classNames.text}>Inloggen</span>
                                </LinkButton>
                                <LinkButton className={ clsx(classNames.linkButton, classNames.register) } to="/portaal/aanmelden">
                                    <RegisterIcon className={classNames.icon}/>
                                    <span className={classNames.text}>Aanmelden</span>
                                </LinkButton>
                            </div>
                        }
                        { isLoggedIn() &&
                            <div className={classNames.linkButtons}>
                                <LinkButton className={classNames.linkButton} to="/portaal/dashboard">
                                    <ThListIcon className={classNames.icon} />
                                    <span className={classNames.text}>Dashboard</span>
                                </LinkButton>
                            </div>
                        }
                    </section>
                    <section className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="small">Vragen?</Heading>
                        <nav className={ classNames.navigation }>
                            <ul className={ classNames.list }>
                                <li className={ classNames.listItem }>
                                    <a className={ classNames.anchor } href="mailto:info@woninglabel.nl">
                                        <ChevronRightIcon className={ classNames.icon } />
                                        info@woninglabel.nl
                                    </a>
                                </li>
                                <li className={ classNames.listItem }>
                                    <a className={ classNames.anchor } href="tel:0850162080">
                                        <ChevronRightIcon className={ classNames.icon } />
                                        085 – 01 620 80
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </section>
                </div>
            </div>
            <div className={ classNames.bottomBar }>
                <div className={ classNames.container }>
                    <div className={ classNames.socialMedia }>
                        <a aria-label="LinkedIn" className={ classNames.linkedIn } href="https://www.linkedin.com/company/woninglabel-nl/" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon className={ classNames.icon } />
                        </a>
                        <a aria-label="Facebook" className={ classNames.facebook } href="https://www.facebook.com/Woninglabel.nl/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon className={ classNames.icon } />
                        </a>
                        <a aria-label="Instagram" className={ classNames.instagram } href="https://www.instagram.com/woninglabel.nl/" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon className={ classNames.icon } />
                        </a>
                        <a aria-label="YouTube" className={ classNames.youTube } href="https://www.youtube.com/channel/UCkq5TR3omAuxtmWO80s9FBA" target="_blank" rel="noopener noreferrer">
                            <YouTubeIcon className={ classNames.icon } />
                        </a>
                    </div>
                    <nav className={ classNames.navigation }>
                        <ul className={ classNames.list }>
                            { data.wordpressFooterMenuThree.menuItems.nodes.map((menuItemNode) => (
                                <li className={ classNames.listItem } key={ menuItemNode.id }>
                                    <a className={ classNames.anchor } href={ menuItemNode.url }>
                                        <span className={ classNames.text }>{ menuItemNode.label }</span>
                                    </a>
                                </li>
                            )) }
                            <li className={ classNames.listItem }>
                                <div className={ classNames.anchor }>
                                    <span className={ classNames.text }>NL09 INGB 0008 3316 73 t.n.v. Woninglabel BV</span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <div className={ classNames.logoWrapper }>
                        <Logo className={ classNames.logo } />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default SiteFooter;
