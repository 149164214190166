import React from "react";

import { Helmet } from "react-helmet";

import { PropsWithChildren } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";

import SiteFooter from "@js/component/SiteFooter";
import SiteHeader from "@js/component/SiteHeader";

// import CookieConsent from "react-cookie-consent";

import "@css/component/Layout.module.scss";

import StoreState from "@js/store/StoreState";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        generalSettings: {
            description: string;
            language: string;
            title: string;
            url: string;
        };
    };
};

/**
 * @type LayoutProps
 */
export type LayoutProps = PropsWithChildren<{}>;

/**
 * @const Layout
 */
const Layout = (props: LayoutProps) => {
    const {
        children
    } = props;

    const data: WordpressData = useStaticQuery(graphql`
        query {
            wordpress {
                generalSettings {
                    description
                    language
                    title
                    url
                }
            }
        }
    `);

    const LogoStructuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        url: data.wordpress.generalSettings.url,
        logo: `${ data.wordpress.generalSettings.url }/wp-content/uploads/2020/12/Woninglabel_logo_blauw_wit_RGB_social.png`,
    }

    const location = useLocation();

    const area = useSelector((storeState: StoreState) => storeState.information.area);
    const areaCode = useSelector((storeState: StoreState) => storeState.information.areaCode);
    const zipCode = useSelector((storeState: StoreState) => storeState.information.zipCode);
    const buildingType = useSelector((storeState: StoreState) => storeState.information.buildingType);
    const visitorMethod = useSelector((storeState: StoreState) => storeState.filter.methodology);

    useEffect(() => {
        const dataLayerData: { event: string } = {
            "event": "push"
        };

        if (zipCode) {
            dataLayerData.visitorZIP = zipCode;
        }

        if (buildingType) {
            dataLayerData.visitorType = buildingType;
        }

        if (areaCode) {
            dataLayerData.visitorSurface = areaCode;
        }

        if (visitorMethod) {
            dataLayerData.visitorMethod = visitorMethod;
        }

        typeof window == "object" && window.gtag && window.gtag("set", dataLayerData);

    }, [location.pathname, area, zipCode, buildingType]);

    return (
        <>
            <Helmet titleTemplate={ `%s | ${ data.wordpress.generalSettings.title }` }>
                <html lang={ data.wordpress.generalSettings.language.replace("_", "-") } />

                <meta charSet="UTF-8" />

                <meta content="initial-scale=1.0, width=device-width" name="viewport" />
                <meta content="follow, index" name="robots" />

                <meta content={ data.wordpress.generalSettings.description } name="description" />

                <title>Home</title>

                <link href="https://fonts.gstatic.com" rel="preconnect" />
                <link href="https://connect.facebook.net" rel="preconnect" />

                {/*<link href="https://fonts.googleapis.com/css2?family=Passion+One:wght@400;600;700&display=swap" rel="stylesheet" />*/}
                {/*<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap" rel="stylesheet" />*/}

                <script type="application/ld+json">
                    { JSON.stringify(LogoStructuredData ) }
                </script>
            </Helmet>
            <SiteHeader />
            { children }

            {/*<CookieConsent*/}
            {/*    buttonStyle={{ padding: "15px 20px", borderRadius: "2px", fontFamily: "Passion One, sans-serif", fontSize: "1.1875rem", color: "#FFFFFF", background: "#ED9509", textTransform: "uppercase" }}*/}
            {/*    buttonText="Accepteren"*/}
            {/*    expires={150}*/}
            {/*    location="bottom"*/}
            {/*    style={{ background: "#F8FAFF", color: "#4B4B4B", boxShadow: "0px 0 10px rgba(0, 0, 0, 0.8)"}}*/}
            {/*>*/}
            {/*    Wij, en derde partijen, maken op onze website gebruik van cookies.*/}
            {/*    Wij gebruiken cookies voor het bijhouden van statistieken (de cookies van Google Analytics*/}
            {/*    zijn volledig geanonimiseerd), om voorkeuren op te slaan maar ook voor marketingdoeleinden.*/}
            {/*    Door op 'Accepteren' te klikken ga je akkoord met het gebruik van alle cookies.*/}
            {/*</CookieConsent>*/}

            <SiteFooter />
        </>
    );
};

export default Layout;
