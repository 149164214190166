import {AnyAction} from "redux";

import StoreState from "@js/store/StoreState";
import {
    SET_ADVISOR_URL,
    SET_AREA,
    SET_AREA_CODE,
    SET_AREA_CODE_BAG,
    SET_AREA_MANUAL,
    SET_BUILDING_TYPE,
    SET_BUILDING_TYPE_GROUP,
    SET_HAS_COMPLETED,
    SET_HAS_VISITED,
    SET_HOUSE_NUMBER,
    SET_HOUSE_NUMBER_ADDITION,
    SET_PROMOTION,
    SET_ZIP_CODE
} from "@js/actionTypes/informationActionTypes";

import {AnyInformationAction} from "@js/actions/informationActions";

/**
 * @type InformationState
 */
type InformationState = StoreState["information"];

/**
 * @const informationReducer
 */
const informationReducer = (state: InformationState, action: AnyAction) => {
    const informationAction = action as AnyInformationAction;

    switch (informationAction.type) {
        case SET_ADVISOR_URL: {
            return { ...state, advisorUrl: informationAction.payload.advisorUrl };
        }

        case SET_AREA: {
            return { ...state, area: informationAction.payload.area };
        }

        case SET_AREA_CODE: {
            return { ...state, areaCode: informationAction.payload.areaCode };
        }

        case SET_AREA_CODE_BAG: {
            return { ...state, areaCodeBag: informationAction.payload.areaCodeBag };
        }

        case SET_AREA_MANUAL: {
            return { ...state, areaManual: informationAction.payload.areaManual };
        }

        case SET_BUILDING_TYPE_GROUP: {
            return { ...state, buildingTypeGroup: informationAction.payload.buildingTypeGroup };
        }

        case SET_BUILDING_TYPE: {
            return { ...state, buildingType: informationAction.payload.buildingType };
        }

        case SET_HAS_COMPLETED: {
            return { ...state, hasCompleted: informationAction.payload.hasCompleted };
        }

        case SET_HAS_VISITED: {
            return { ...state, hasVisited: informationAction.payload.hasVisited };
        }

        case SET_HOUSE_NUMBER: {
            return { ...state, houseNumber: informationAction.payload.houseNumber };
        }

        case SET_HOUSE_NUMBER_ADDITION: {
            return { ...state, houseNumberAddition: informationAction.payload.houseNumberAddition };
        }

        case SET_PROMOTION: {
            return { ...state, promotion: informationAction.payload.promotion };
        }

        case SET_ZIP_CODE: {
            return { ...state, zipCode: informationAction.payload.zipCode };
        }

        default: {
            return { ...state };
        }
    }
};

export default informationReducer;
